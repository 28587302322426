<template>
  <modal
    id="ModalNovoEditaAluno"
    name="modalNameNovoAluno"
    :height="'auto'"
    :width="'40%'"
    :adaptive="true"
    :draggable="true"
    @opened="verificaDadosToEdit()"
    @closed="
      limpaDadosToEdit();
      getUnidadesDB({ token: $store.getters.getPerfilToken });
      $emit('modal-fechado');
    "
  >
    <div class="container primeira-div-modal position-relative py-4">
      <div id="div__imgPerfil">
        <label for="imgPerfil">
          <img id="fotoPerfilMeusDadosAluno" :src="obj.file" />
        </label>
        <input
          @change="onChoseFile"
          type="file"
          name=""
          id="imgPerfil"
          accept="image/x-png, image/jpeg"
          hidden
        />
      </div>
      <div id="checkMaisDeUmaTurma" class="position-absolute" hidden>
        <div class="d-flex j-c-center">
          <label for="" class="mr-2 mt-1"><span>+</span> de 1 turma</label>
          <input type="checkbox" name="" id="" />
        </div>
      </div>
      <div class="col-md-12 d-flex j-c-center">
        <label for="">Dados do Aluno</label>
      </div>
      <div>
        <div class="row text-left">
          <div class="col-md-11 mx-auto">
            <ValidationObserver
              ref="form"
              tag="form"
              class="text-left row"
              @submit.prevent="mudarSenha()"
            >
              <div :class="!dadosToEdit.id ? 'col-md-12' : 'col-md-8'">
                <label for="">Unidade</label>
                <input type="text" disabled :value="$route.params.unidade" />
              </div>
              
              <div v-if="dadosToEdit.id" class="col-md-4">
                <label for="">Código</label>
                <input type="text" disabled :value="obj.cod" />
              </div>
              <!-- <ValidationProvider
                rules="excluded:0|required"
                name="'Turma do Aluno'"
                class="col-md-6"
                v-slot="{ errors, ariaMsg, classes }"
              >
                <label for="">Turma</label>
                <select
                  name=""
                  id=""
                  v-model="obj.turma"
                  class="text-left"
                  @change="changeTurmaId()"
                >
                  <optgroup label="Turma Atual">
                    <option :value="currentClass">{{ currentClass }}</option>
                  </optgroup>
                  <optgroup
                    v-if="turmas.length != 1"
                    :label="turmas.length > 2 ? 'Outras Turmas' : 'Outra Turma'"
                  >
                    <option
                      :value="item"
                      v-for="item in turmas"
                      :key="item.id"
                      v-show="item.cod != $route.params.class"
                    >
                      {{ item.cod }}
                    </option>
                  </optgroup>
                </select>
                <span class="span-status-validate">{{ errors[0] }}</span>
              </ValidationProvider> -->
              <ValidationProvider
                rules="required"
                name="'Nome do Aluno'"
                :bails="false"
                class="col-md-12 mt-3"
                v-slot="{ errors, ariaMsg, classes }"
                tag="div"
              >
                <div class="control" :class="classes">
                  <label for="">Nome Completo</label>
                  <input
                    type="text"
                    v-model="obj.name"
                    :class="classes"
                    @blur="geraCodigoAluno"
                  />
                  <span class="span-status-validate">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider
                rules="excluded:0|required"
                name="'Ano letivo'"
                class="col-md-6 mt-3"
                v-slot="{ errors, ariaMsg, classes }"
              >
                <label for="">Ano letivo</label>
                <select
                  name=""
                  id=""
                  v-model="obj.ano_letivo"
                  class="text-left"
                >
                  <optgroup
                    v-for="(item, index) in objAnoLetivo"
                    :key="index"
                    :label="index.replaceAll('_', ' ')"
                  >
                    <option v-for="series in item" :key="series">
                      {{ series }}
                    </option>
                  </optgroup>
                </select>
                <span class="span-status-validate">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors, ariaMsg, classes }"
                rules="validadeCpf"
                name="CPF"
                :bails="false"
                class="col-md-6 mt-3"
                tag="div"
              >
                <div class="control" :class="classes">
                  <label for="">CPF</label>
                  <input
                    @change="geraCodigoAluno"
                    v-mask="'###.###.###-##'"
                    class="form-control"
                    :class="classes"
                    type="text"
                    v-model="obj.cpf"
                  />
                  <div class="text-left">
                    <span v-bind="ariaMsg" class="span-status-validate">{{
                      errors[0]
                    }}</span>
                  </div>
                </div>
              </ValidationProvider>
              <div class="col-md-12 mt-3 d-flex j-c-center">
                <label for="">Dados do Responsável</label>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  name="'Nome Completo do Responsável'"
                  v-slot="{ errors, ariaMsg, classes }"
                >
                  <label for="">Nome Completo</label>
                  <div class="d-flex">
                    <input
                      class="mr-2"
                      type="checkbox"
                      style="width: 15px"
                      @change="
                        inputNoContentNomeResponsavel =
                          !inputNoContentNomeResponsavel
                      "
                      checked
                    />
                    <input
                      v-model="obj.nome_responsavel"
                      type="text"
                      :disabled="inputNoContentNomeResponsavel"
                      :placeholder="
                        inputNoContentNomeResponsavel
                          ? 'Não informado'
                          : obj.nome_responsavel
                      "
                    />
                  </div>

                  <span class="span-status-validate">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  name="'E-mail do Responsável'"
                  v-slot="{ errors, ariaMsg, classes }"
                >
                  <label for="">E-mail</label>
                  <div class="d-flex">
                    <input
                      class="mr-2"
                      type="checkbox"
                      style="width: 15px"
                      @change="inputNoContentEmail = !inputNoContentEmail"
                      checked
                    />
                    <input
                      v-model="obj.email"
                      type="email"
                      :disabled="inputNoContentEmail"
                      :placeholder="
                        inputNoContentEmail ? 'Não informado' : obj.emai
                      "
                    />
                  </div>

                  <span class="span-status-validate">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <ValidationProvider
                name="'Telefone do Responsável'"
                class="col-md-6 mx-auto mt-3"
                tag="div"
                v-slot="{ errors, ariaMsg, classes }"
              >
                <label for="">Telefone</label>
                <div class="d-flex">
                  <input
                    class="mr-2"
                    type="checkbox"
                    style="width: 15px"
                    @change="inputNoContentTelefone = !inputNoContentTelefone"
                    checked
                  />
                  <input
                    v-model="obj.telefone_responsavel"
                    type="text"
                    v-mask="
                      obj.telefone_responsavel.length <= 14
                        ? '(##) ####-####'
                        : '(##) # ####-####'
                    "
                    :placeholder="
                      inputNoContentTelefone ? 'Não informado' : obj.phone
                    "
                    :disabled="inputNoContentTelefone"
                  />
                </div>

                <span class="span-status-validate">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors, ariaMsg, classes }"
                rules="min:6"
                name="'Senha'"
                :bails="false"
                class="col-md-6 mx-auto"
                tag="div"
              >
                <div class="control mt-3" :class="classes">
                  <label for="">Senha</label>
                  <div class="d-flex">
                    <div class="w-100 mr-2">
                      <input
                        type="text"
                        v-model="obj.password"
                        class="form-control"
                        :class="classes"
                      />
                    </div>
                    <div
                      class="bg-42cc7e hoverStyleButton"
                      style="border-radius: 5px"
                    >
                      <span
                        @click="obj.password = geradorSenhas()"
                        class="d-flex j-c-center w-100 h-100"
                      >
                        <i class="fas fa-key color-fff px-2"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <span v-bind="ariaMsg" class="span-status-validate">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </ValidationObserver>
          </div>
          <div class="col-md-12">
            <div
              class="mt-4 btns d-flex align-items-center justify-content-center"
            >
              <button
                class="btn-style-default color-fff bg-d42338"
                @click="hideModal('modalNameNovoAluno')"
                :disabled="load"
              >
                cancelar
              </button>
              <button
                class="btn-style-default color-fff bg-0e5caf ml-3"
                type="submit"
                :disabled="load"
                @click="salvarAluno()"
              >
                <span v-if="!load"> {{ statusEdicao }}</span>
                <div v-else class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </button>
              <!--<button class="btn-style-default color-fff bg-0e5caf ml-3" @click="chamaProximaModalLinkaTema()">próximo</button>-->
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          btns
          position-absolute
          d-flex
          align-items-center
          justify-content-center
          col-md-12
        "
      >
        <button
          class="btn-style-default color-fff bg-d42338"
          @click="hideModal('modalNameNovoAluno')"
        >
          cancelar
        </button>
        <button
          class="btn-style-default color-fff bg-0e5caf ml-3"
          @click="hideThisModalShowParaSalvar()"
        >
          próximo
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
export default {
  data() {
    return {
      statusEdicao: "Salvar",
      unidadeSelected: "",
      show: false,
      userID: null,
      load: false,
      obj: {
        cod: null,
        profile_id: 5,
        name: null,
        turma: null,
        cpf: null,
        password: null,
        unidade_id: this.$route.params.idunidade,
        nome_responsavel: null,
        telefone_responsavel: "",
        file: require("@/assets/aluno/perfilDefault.png"),
        ano_letivo: null,
        file_id: null,
        email: null,
      },
      turmas: [],
      inputNoContentNomeResponsavel: false,
      inputNoContentEmail: false,
      inputNoContentTelefone: false,
      currentClass: this.$route.params.class,
      objAnoLetivo: {
        Fundamental_Iniciais: [
          "1º ano",
          "2º ano",
          "3º ano",
          "4º ano",
          "5º ano",
        ],
        Fundamental_Finais: ["6º ano", "7º ano", "8º ano", "9º ano"],
        Ensino_Médio: ["1º ano", "2º ano", "3º ano"],
        Outros: ["Curso", "Treino"],
      },
    };
  },
  props: ["dadosToEdit"],
  computed: {
    ...mapGetters(["getUnidades"]),
  },
  mounted() {
    this.chamaTurmas();
    setTimeout(() => console.log(this.$route.params), 2000)
  },
  methods: {
    ...mapActions(["getUnidadesDB"]),
    hideThisModalShowParaSalvar() {
      this.$emit("chamaFormularioModal");
    },
    changeSelect(val) {
      this.selecting = val;
    },
    chamaTurmas() {
      this.getUnidades.forEach((element) => {
        if (element.id == this.$route.params.idunidade) {
          this.turmas = [];
          this.turmas = element.turmas;
        }
      });
    },
    salvarAluno() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.obj.cpf =
            this.obj.cpf == null ? null : this.obj.cpf.replace(/[^\d]+/g, "");
          this.statusEdicao = null;
          $.ajax({
            type: `${this.dadosToEdit.id ? "PUT" : "POST"}`,
            url: `${this.VUE_APP_CMS}api/users${
              this.dadosToEdit.id ? `/${this.dadosToEdit.id}` : ""
            }`,
            data: {
              name: this.obj.name,
              turma: this.obj.turma,
              ano_letivo: this.obj.ano_letivo,
              cod: this.obj.cod,
              cpf: this.obj.cpf,
              password: this.obj.password,
              unidade_id: this.$route.params.idunidade,
              turma_id: this.$route.params.idclass,
              nome_responsavel: this.obj.nome_responsavel,
              telefone_responsavel: this.obj.telefone_responsavel,
              file: this.obj.file,
              profile_id: this.obj.profile_id,
              email: this.obj.email,
            },
            dataType: "json",
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
              // "Content-Type": "application/x-www-form-urlencodd"
            },
            success: (response) => {
              this.toastGlobal(
                "success",
                `O aluno '${this.obj.name}' foi adicionado com sucesso`
              );

              this.vinculaAlunoTurma();
              this.statusEdicao = "Salvar";
              this.hideModal("modalNameNovoAluno");
              this.$emit("atualizaDados");
              this.resetFormulario();
            },
            error: (response) => {
              this.load = false;
              this.statusEdicao = "Salvar";
              this.toastGlobal("error", `Erro: ${response.responseJSON.msg}`);
            },
          });
        } else {
          this.toastGlobal("error", `Preencha o formulário devidamente`);
        }
      });
    },
    vinculaAlunoTurma() {
      this.getIdAluno();
    },
    getIdAluno() {
      $.ajax({
        type: `POST`,
        url: `${this.VUE_APP_CMS}api/login`,
        data: {
          cpf: this.obj.cpf ? this.obj.cpf.replace(/[^\d]+/g, "") : null,
          password: this.obj.password,
        },
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
          // "Content-Type": "application/x-www-form-urlencodd"
        },
        success: (response) => {
          let alunoObj = {
            user_id: response.user_id,
            turma_id: this.$route.params.idclass,
          };
          $.ajax({
            type: `POST`,
            url: `${this.VUE_APP_CMS}api/turmaUser`,
            data: alunoObj,
            dataType: "json",
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
              // "Content-Type": "application/x-www-form-urlencodd"
            },
            success: (response) => {
              this.toastGlobal(
                "success",
                `O aluno foi vinculado à turma '${this.$route.params.class}' da unidade ${this.$route.params.unidade}`
              );
            },
            error: (response) => {
              this.show = false;
              this.statusEdicao = "Salvar";
              this.toastGlobal("error", `Erro: ${response.responseJSON.msg}`);
            },
          });
        },
        error: (response) => {
          this.userID = null;
        },
      });
    },
    resetFormulario() {
      this.obj = {
        cod: null,
        profile_id: 5,
        name: null,
        turma: null,
        cpf: null,
        password: null,
        unidade_id: this.$route.params.idunidade,
        nome_responsavel: null,
        telefone_responsavel: "",
        file: require("@/assets/aluno/perfilDefault.png"),
        ano_letivo: null,
        file_id: null,
        email: null,
      };
    },
    geraCodigoAluno() {
      // if(this.obj.cpf){
      //   this.obj.cod = `${this.$route.params.class}${this.obj.cpf
      //   .replace(/[^\d]+/g, "")
      //   .substr(9, 10)}`;
      // }
      // let theCode = this.obj.name
      //   .replace("de", "")
      //   .replace("da", "")
      //   .replace("dos", "")
      //   .split(" ");
      // theCode = this.codeToStudent(theCode)
      // this.obj.cod = `${this.$route.params.class}${theCode[0]}${theCode[2]}${theCode[1]}`
    },
    verificaDadosToEdit() {
      if (this.dadosToEdit.cod) {
        this.obj.file = this.dadosToEdit.file
          ? this.dadosToEdit.file
          : require("@/assets/aluno/perfilDefault.png");
        this.obj.cod = this.dadosToEdit.cod;
        this.obj.profile = this.dadosToEdit.profile;
        this.obj.name = this.dadosToEdit.name;
        this.obj.email = this.dadosToEdit.email;
        this.obj.cpf = this.dadosToEdit.cpf;
        this.obj.nome_responsavel = this.dadosToEdit.nome_responsavel;
        this.obj.turma = this.$route.params.class;
        this.obj.ano_letivo = this.dadosToEdit.ano_letivo;
        this.obj.telefone_responsavel = this.dadosToEdit.telefone_responsavel;
      }
    },
    limpaDadosToEdit() {
      this.obj.name = null;
      this.obj.turma = null;
      this.obj.email = null;
      this.obj.cpf = null;
      this.obj.telefone_responsavel = "";
    },
    changeTurmaId() {},
    onChoseFile: function (event) {
      // Reference to the DOM input element
      var input = event.target;

      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        if (Math.round(input.files[0].size / 1024) >= 5000) {
          this.toastGlobal(
            "success",
            "Seu arquivo é maior que 5MB! Por favor utilize um compressor ou selecione outro arquivo! \r"
          );
          return false;
        }
        var reader = new FileReader();
        reader.onload = (e) => {
          this.obj.file = e.target.result;
          this.$nextTick(() => {
            // this.$store.commit("SET_FOTO_PERFIL", input);
            this.sendFoto();
          });
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
  },
};
</script>

<style scoped>
.dropdown {
  width: 100%;
}

.dropdown img {
  height: 20px !important;
  width: 20px !important;
}

#checkMaisDeUmaTurma {
  top: 0;
  right: 15px;
}

#checkMaisDeUmaTurma input {
  width: 20px;
  height: 20px;
}

#div__imgPerfil img {
  background-color: #c0c0c0;
  width: 75px;
  height: 75px;
  border-radius: 100%;
}
</style>
