<template>
  <modal
    id="modalFormularioNovoAluno"
    name="modalNameFormularioNovoAluno"
    :height="'auto'"
    :width="'70%'"
    :adaptive="true"
    :draggable="true"
  >
    <div class="d-flex justify-content-center vertical-align-center">
      <meusDadosComponent
        @salvaFormulario="salvarFormulario"
        :bg_color="'bg-fff'"
        :targetDoing="'Dados do Novo Aluno'"
        :container="'container-fluid'"
        :px="'px-0'"
        :alterar_foto="false"
      />
    </div>
  </modal>
</template>

<script>
import meusDadosComponent from "../meusDadosComponent";
export default {
  data() {
    return {};
  },
  components: {
    meusDadosComponent,
  },
  methods: {
    hideThisModalShowParaSalvar() {
      this.$emit("chamaFormularioModal");
    },
    salvarFormulario() {
      this.$emit("salvouSucesso");
      this.hideModal("modalNameFormularioNovoAluno");
    },
  },
};
</script>

<style scoped></style>
