<template>
  <div class="here bg-fff py-5" @atualizarAlunos="atualizarAlunos">
    <ModalNovoEditaAluno
      @atualizaDados="atualizaDados"
      :dadosToEdit="dadosToEdit"
      @modal-fechado="recarregarPagina()()"
    />
    <ModalFormulario @salvouSucesso="salvoComSucesso" />

    <ModalConfirmacao
      :vontade="`que '${DadosToDelete.name}' seja`"
      :elementoVontade="'deletado'"
      @deletarUsuario="deletarAluno"
    />
    <div class="container">
      <div class="row">
        <div class="lupa col-md-12 mx-auto text-center d-flex my-4 px-0">
          <div class="position-relative">
            <img
              class="position-absolute"
              src="@/assets/icones/lupa.png"
              alt="lupa.png"
            />
            <input
              v-model="inputPesquisar"
              type="text"
              placeholder="Digite o nome ou o código para pesquisar "
              class="shadow-sm border-default shadow-sm"
            />
          </div>
        </div>
        <div class="col-md-12 text-left pl-0 mb-3">
          <h2 class="title-dados pb-2">{{ $route.params.unidade }}</h2>
          <router-link :to="`/administrador/unidades-e-instituicoes/${this.$route.params.idunidade}/${this.$route.params.unidade}/`">
          <button class="mt-3" style="background: #0E5CAF; color: white; padding: 4px 19px; font-size: 16px">Voltar</button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container bg-fff in-here shadow-sm">
      <div class="row pb-4">
        <div class="col-md-11 mx-auto text-left mt-3 pb-2 px-0">
          <!-- <router-link :to="`/administrador/unidades-e-instituicoes/`"
            id="btn-voltar"
            class="btn-style-default-less-2 color-fff bg-0e5daf mr-3 mb-3 f-w-700"
            @click="emitParaMudarCP('administradorInstituicoes')"
          >
            voltar para turmas
          </router-link> -->
          <div
            class="
              identify
              d-flex
              align-items-center
              justify-content-center
              mb-4
            "
          >
            <div
              id="alunoPhotoPerfil"
              class="ml-3"
              :style="`background-image: url(${
                theProfessor && theProfessor.file
                  ? theProfessor.file
                  : require('@/assets/aluno/perfilDefault.png')
              })`"
            ></div>
            <div :class="`dadosNivel d-flex justify-content-between `">
              <div
                id="idStatusPerfil"
                class="
                  px-2
                  mr-4
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <p>
                  {{
                    theProfessor
                      ? theProfessor.name
                      : "Professor não cadastrado"
                  }}
                </p>
              </div>
              <div
                class="d-flex align-items-center justify-content-center ml-3"
              >
                <router-link
                  hidden
                  :to="''"
                  class="text-uppercase text-center color-454c57 f-w-700"
                  >ver <br />
                  perfil</router-link
                >
              </div>
            </div>
          </div>
          <h2 class="title-dados pb-2">Progresso Por Aluno</h2>
        </div>
        <div
          class="
            col-md-11
            mx-auto
            h2
            row
            text-left
            border-bottom
            mt-5
            pb-2
            px-0
            d-flex
            justify-content-between
          "
        >
          <div class="col-md-2 p-0 d-flex justify-content-center">
            <h2>Código</h2>
          </div>
          <div class="col-md-3 p-0 d-flex justify-content-center">
            <h2>Alunos</h2>
          </div>
          <div class="col-md-1 p-0 d-flex justify-content-center">
            <h2>Progresso</h2>
          </div>
          <div class="col-md-3 p-0 d-flex justify-content-center">
            <h2>Tema Atual</h2>
          </div>
          <div class="col-md-2 p-0 d-flex justify-content-center"></div>
        </div>
        <div
          class="
            col-md-11
            mx-auto
            h2
            row
            text-left
            border-bottom
            py-3
            px-0
            d-flex
            justify-content-between
          "
          v-for="(item, index) in alunos"
          :key="index"
        >
          <div
            class="col-md-2 d-flex c-center p-0 d-flex justify-content-center"
          >
            <h3>{{ item.cod }}</h3>
          </div>
          <div
            class="col-md-3 d-flex c-center p-0 d-flex justify-content-center"
          >
            <h3>{{ item.name }}</h3>
          </div>
          <div
            class="col-md-1 d-flex c-center p-0 d-flex justify-content-center"
          >
            <h3>{{ item.progresso }}</h3>
          </div>
          <div
            class="col-md-3 d-flex c-center p-0 d-flex justify-content-center"
          >
            <h3>{{ item.tema_atual }}</h3>
          </div>
          <div class="col-md-2 d-flex justify-content-end pr-0">
            <router-link
              tag="button"
              :to="`/${verifyStatus(
                getUserDatas.profile_id
              ).toLowerCase()}/unidades-e-instituicoes/${
                statusSalva.theRoute.idunidade
              }/${statusSalva.theRoute.unidade}/${
                statusSalva.theRoute.idclass
              }/${statusSalva.theRoute.class}/statusAluno/${item.id}/${
                item.name
              }`"
              class="btn-style-default-less-2 color-fff bg-222541 mr-1 tamanho"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </router-link>
            <button
              class="btn-style-default-less-2 color-fff bg-fedd0a mr-1"
              @click="editarAluno(item)"
              v-if="getUserDatas.profile_id != 4"
            >
              <i class="fa fa-edit color-000" aria-hidden="true"></i>
            </button>
            <button
              class="btn-style-default-less-2 color-fff bg-d42338 mr-1"
              @click="VerificarDeletarAluno(item)"
              v-if="getUserDatas.profile_id != 4"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
            <button
              class="btn-style-default-less-2 color-fff bg-0e5daf"
              @click="chamaModalVincularTema(item.id)"
            >
              <i class="fas fa-share-alt"></i>
            </button>
          </div>
        </div>
        <div
          class="col-md-11 mx-auto d-flex justify-content-start mt-5 px-0"
          v-if="getUserDatas.profile_id != 3"
        >
          <button
            class="btn-style-default-less-2 color-fff bg-0e5daf"
            @click="novoAluno()"
            v-if="getUserDatas.profile_id != 4"
          >
            novo aluno
            <i class="icon-plus ml-1"
              ><img src="@/assets/icones/iconPlus.png" alt="iconPlus.png"
            /></i>
          </button>
          <modalVinculaTemaExercicio :temaDados="idUser" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalNovoEditaAluno from "@/components/modais/ModalNovoEditaAluno";
import ModalFormulario from "@/components/modais/ModalFormulario";
import ModalConfirmacao from "@/components/modais/ModalConfirmacao";
import modalVinculaTemaExercicio from "@/components/modais/ModalVincularTurmaAluno";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      idUser: "",
      statusSalva: {
        target: "Novo Aluno",
        status: "Adicionado com sucesso",
        theRoute: this.$route.params,
      },
      DadosToDelete: {},
      inputPesquisar: "",
      alunos: [],
      alunosBK: [],
      theProfessor: {},
      dadosToEdit: {},
    };
  },
  components: {
    modalVinculaTemaExercicio,
    ModalNovoEditaAluno,
    ModalFormulario,
    ModalConfirmacao,
  },
  watch: {
    inputPesquisar: function () {
      this.alunos = this.pesquisarAluno(this.inputPesquisar);
    },
    // getUnidades: function() {
    //   this.turmas = this.filtrosDePesquisaDadosTurmas(this.inputPesquisar);
    //   this.$store.dispatch("getUnidadesDB", {
    //     token: this.$store.getters.getPerfilToken,
    //   });
    //   this.$store.dispatch("getTurmasDB", this.$store.getters.getPerfilToken);
    //   this.getUnidades.forEach((element) => {
    //     if (element.id == this.$route.params.idunidade) {
    //       this.$store.commit("SET_TURMAS_LISTA", element.turmas);
    //       // this.turmas = element.turmas
    //     }
    //   });
    //   this.recebeAlunos();
    // },
  },
  created() {
    this.recebeAlunos();
    console.log("parametro", this.$route);
  },
  computed: {
    ...mapGetters(["getTurmas", "getUnidades", "getUserDatas"]),
  },
  methods: {
    atualizarAlunos(payload) {
      this.alunos = payload;
      console.log("listou");
    },
    chamaModalVincularTema(id) {
      console.log(id);
      this.idUser = id;
      this.showModal("modalNameVinculaTemaExercicio");
    },
    recarregarPagina() {
      setTimeout(() => {
        // location.reload();
      }, 1000);
    },
    emitParaMudarCP(val) {
      this.$emit("mudaComponente", val);
    },
    salvoComSucesso() {
      this.showModal("modalNameAdicionadoSucesso");
    },
    recebeAlunos() {
      this.$store.getters.getTurmas[0].forEach((element) => {
        if (element.id == this.$route.params.idunidade) {
          element.turmas.forEach((el) => {
            if (el.cod == this.$route.params.class) {
              this.alunos = el.alunos;
              this.alunosBK = el.alunos;
            }
          });
        }
      });
    },
    pesquisarAluno(stringPesquisa = "") {
      // var pesquisa = this.searchJuan;
      let retornoPesquisa;
      let buscaDados = this.alunosBK;

      if (stringPesquisa.length == 0) {
        retornoPesquisa = buscaDados;
      } else {
        retornoPesquisa = buscaDados.filter((item) => {
          let code = item.cod;
          let name = item.name;
          // let temaAtual = item.temaAtual
          return (
            name.toUpperCase().includes(stringPesquisa.toUpperCase()) ||
            code.includes(stringPesquisa)
          );
        });
      }
      return retornoPesquisa;
    },
    deletarAluno() {
      let objToDelete = {
        typeAjax: "DELETE",
        cod: this.DadosToDelete.id,
      };
      this.$store.dispatch("delUser", objToDelete).then((resolve) => {
        if (resolve.valid) {
          this.toastGlobal(
            "success",
            `O usuário ${this.DadosToDelete.name} foi deletado com sucesso.`
          );

          this.hideModal("modalNameConfirmacao");
          this.atualizaDados();
        } else {
          this.toastGlobal("error", resolve.msg.reject.msg);
        }
      });
    },
    VerificarDeletarAluno(item) {
      this.showModal("modalNameConfirmacao");
      this.DadosToDelete = item;
    },
    recebeTurmas() {
      this.$store
        .dispatch("getTurmasDB", this.$store.getters.getPerfilToken)
        .then((resolve) => {
          if (resolve.valid) {
            this.recebeAlunos();
            this.toastGlobal("success", "Dados atualizados com sucesso");
          } else {
            this.toastGlobal(
              "error",
              `Algo de errado ocorreu e você não está vendo os dados da forma correta... \n erro: ${resolve.msg}`
            );
          }
        });
    },
    async atualizaDados() {
      const response = await fetch(
        `${this.VUE_APP_CMS}api/unidades/getAlunos${this.$route.params.idclass ? `/${this.$route.params.idclass}` : ''}`,
        {
          headers: {
            Authorization: this.$store.getters.getPerfilToken,
            // "Content-Type": "application/x-www-form-urlencodd"
          },
        }
      );
      const responseJSON = await response.json();
      this.alunos = responseJSON[0];
      responseJSON.forEach((el) => {
        if (el.cod == this.$route.params.class) {
          console.log("if");
          this.alunos = el.alunos;
          this.alunosBK = el.alunos;
        }
      });
      this.$store.dispatch("getUnidadesDB", {
        token: this.$store.getters.getPerfilToken,
      });
      this.$store.dispatch("getTurmasDB", this.$store.getters.getPerfilToken);
      this.getUnidades.forEach((element) => {
        if (element.id == this.$route.params.idunidade) {
          this.theProfessor = element.turmas[0].professor;
          this.$store.commit("SET_TURMAS_LISTA", element.turmas);
        }
      });
    },
    novoAluno() {
      this.dadosToEdit = {};
      this.showModal("modalNameNovoAluno");
    },

    editarAluno(dados) {
      this.dadosToEdit = dados;
      this.showModal("modalNameNovoAluno");
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 14px;
}

h3,
button {
  font-size: 12px;
  font-weight: 400;
}

input {
  height: 40px;
  border: 1px solid #e4e5e8;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}

.icon-plus img {
  width: 15px;
}

input[type="date"] {
  width: 140px;
}

.date label {
  margin: 0;
  font-size: 10px;
}

.identify {
  width: auto !important;
  background-color: #edf0f5;
}

.identify a {
  font-size: 10px;
  margin: 0;
}
</style>
